var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Tasks","title-tag":"h3","hide-footer":"","size":"lg","id":"modalSNEditTask","no-close-on-backdrop":""},on:{"hidden":_vm.close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',[_c('b-form-group',{attrs:{"label":"Lead","label-for":"lead-name","label-cols-md":"2"}},[_c('b-form-input',{attrs:{"id":"lead-name","value":_vm.lead.lead_name,"readonly":""}})],1)],1)],1),((!_vm.taskForSn && _vm.moduleId === 15) || _vm.isDisabled)?_c('b-col',[_c('validation-provider',[_c('b-form-group',{attrs:{"label":"Type","label-for":"type","label-cols-md":"4"}},[_c('b-form-checkbox',{staticClass:"custom-control-primary pt-50",attrs:{"checked":"true","name":"check-button","switch":"","disabled":_vm.isDisabled},model:{value:(_vm.attend_type),callback:function ($$v) {_vm.attend_type=$$v},expression:"attend_type"}},[_vm._v(" "+_vm._s(_vm.attend_type ? "LATER" : "NOW")+" ")])],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',[_c('b-form-group',{attrs:{"label":"Send SMS","label-for":"sms-status","label-cols-md":"4"}},[_c('b-form-checkbox',{staticClass:"custom-control-primary pt-50",attrs:{"id":"sms-status","checked":"true","name":"check-button","switch":"","disabled":_vm.isDisabled},model:{value:(_vm.task.sms_status),callback:function ($$v) {_vm.$set(_vm.task, "sms_status", $$v)},expression:"task.sms_status"}},[_vm._v(_vm._s(_vm.task.sms_status ? "YES" : "NO")+" ")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12 form-group-md-2"}},[_c('validation-provider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Subject","label-for":"subject","label-cols-md":"2"}},[(_vm.moduleId !== 15 || _vm.taskForSn)?_c('b-form-input',{attrs:{"id":"subject","state":errors[0] ? false : null,"disabled":_vm.isDisabled,"trim":""},model:{value:(_vm.task.subject),callback:function ($$v) {_vm.$set(_vm.task, "subject", $$v)},expression:"task.subject"}}):_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['CALL']},model:{value:(_vm.task.subject),callback:function ($$v) {_vm.$set(_vm.task, "subject", $$v)},expression:"task.subject"}})],1)]}}],null,true)})],1),(_vm.currentUser.role_id === 7)?_c('b-col',{attrs:{"cols":"12 form-group-md-2"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Type","label-for":"method","label-cols-md":"2","state":_vm.getValidationState(validationContext)}},[_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"id":"method","name":"radio-method","disabled":_vm.isDisabled,"options":[
                  { text: 'INSTANTLY', value: 1 },
                  { text: 'PROGRAMED', value: 2 } ]},model:{value:(_vm.task.method),callback:function ($$v) {_vm.$set(_vm.task, "method", $$v)},expression:"task.method"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v("Due Date")]),_c('b-col',[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"state":_vm.getValidationState(validationContext)}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date","placeholder":"Date","config":_vm.configFlatPickr,"disabled":_vm.isDisabled || !_vm.attend_type},model:{value:(_vm.task.date),callback:function ($$v) {_vm.$set(_vm.task, "date", $$v)},expression:"task.date"}})],1)]}}],null,true)})],1),_c('b-col',[_c('b-form-group',[_c('kendo-timepicker',{staticClass:"w-100 rounded bg-transparent",staticStyle:{"height":"2.73rem"},attrs:{"format":'HH:mm',"interval":15,"placeholder":"Hour","disabled":_vm.isDisabled || !_vm.attend_type},model:{value:(_vm.task.hour),callback:function ($$v) {_vm.$set(_vm.task, "hour", $$v)},expression:"task.hour"}})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',[_c('b-form-group',[_c('b-form-input',{attrs:{"value":_vm.lead.state,"readonly":""}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12 form-group-md-2"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Assign to","label-cols-md":"2","label-for":"asigned","state":errors[0] ? false : null}},[(_vm.taskForSn == 0)?_c('v-select',{staticClass:"w-100 select-icon-none",attrs:{"options":_vm.sellers,"clearable":false,"label":"user_name","transition":"","reduce":function (option) { return option.id; },"selectable":function (option) { return !_vm.attend_type
                      ? option.status_session == 1 && option.disabled == 0
                      : option.disabled == 0; }},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_c('li',[_c('b-row',{staticClass:"mr-0"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('strong',[_vm._v("Seller")])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('strong',[_vm._v("Now")])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('strong',[_vm._v("Later today")])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('strong',[_vm._v("Later other")])])],1)],1)]},proxy:true},{key:"selected-option",fn:function(ref){
                  var status_session = ref.status_session;
                  var user_name = ref.user_name;
                  var count_task = ref.count_task;
                  var instantly_sum = ref.instantly_sum;
                  var later_today = ref.later_today;
                  var later_others = ref.later_others;
return [_c('b-row',{staticClass:"w-100 ml-0 mr-0"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"start","align-items":"center"}},[_c('span',{staticClass:"ml-1 mr-2"},[_c('feather-icon',{style:(status_session == 1
                                ? 'color: #1ab91a;'
                                : 'color: gray;'),attrs:{"icon":"CircleIcon"}})],1),_c('span',[_vm._v(_vm._s(user_name)+"("+_vm._s(count_task)+")")])])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(Number(instantly_sum)))]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(Number(later_today)))]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(Number(later_others)))])],1)]}},{key:"option",fn:function(option){return [_c('b-row',{staticStyle:{"width":"100% !important"}},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"start","align-items":"center"}},[_c('span',{staticClass:"mr-2"},[_c('feather-icon',{style:(option.status_session == 1
                                ? 'color: #1ab91a;'
                                : 'color: gray;'),attrs:{"icon":"CircleIcon"}})],1),_c('span',[_vm._v(_vm._s(option.user_name)+"("+_vm._s(option.count_task)+") "+_vm._s(( !_vm.attend_type ? option.status_session == 1 && option.disabled == 0 : option.disabled == 0 ) ? "" : "(Not available)"))])])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(Number(option.instantly_sum)))]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(Number(option.later_today)))]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(Number(option.later_others)))])],1)]}}],null,true),model:{value:(_vm.seller),callback:function ($$v) {_vm.seller=$$v},expression:"seller"}}):_vm._e(),(_vm.taskForSn == 1)?_c('v-select',{staticClass:"w-100 select-icon-none",attrs:{"options":_vm.sellers,"clearable":false,"label":"user_name","transition":"","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                                var state_advisors = ref.state_advisors;
                                var user_name = ref.user_name;
return [_c('b-row',{staticClass:"w-100 ml-0 mr-0"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"start","align-items":"center"}},[_c('span',{staticClass:"ml-1 mr-2"},[_c('feather-icon',{style:(state_advisors == 1
                                ? 'color: #1ab91a;'
                                : 'color: gray;'),attrs:{"icon":"CircleIcon"}})],1),_c('span',[_vm._v(_vm._s(user_name))])])])],1)]}},{key:"option",fn:function(option){return [_c('b-row',{staticStyle:{"width":"100% !important"}},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"start","align-items":"center"}},[_c('span',{staticClass:"mr-2"},[_c('feather-icon',{style:(option.state_advisors == 1
                                ? 'color: #1ab91a;'
                                : 'color: gray;'),attrs:{"icon":"CircleIcon"}})],1),_c('span',[_vm._v(_vm._s(option.user_name))])])])],1)]}}],null,true),model:{value:(_vm.seller),callback:function ($$v) {_vm.seller=$$v},expression:"seller"}}):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12 form-group-md-2"}},[_c('validation-provider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Content","label-cols-md":"2","label-for":"content"}},[_c('b-form-textarea',{attrs:{"id":"content","rows":"3","disabled":_vm.isDisabled,"state":errors[0] ? false : null},model:{value:(_vm.task.content),callback:function ($$v) {_vm.$set(_vm.task, "content", $$v)},expression:"task.content"}})],1)]}}],null,true)})],1),(_vm.currentUser.role_id === 7)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Sms"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"sms","placeholder":"Write new message","rows":"4","disabled":_vm.isDisabled,"state":_vm.getValidationState(validationContext)},model:{value:(_vm.task.sms),callback:function ($$v) {_vm.$set(_vm.task, "sms", $$v)},expression:"task.sms"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(!_vm.isDisabled)?_c('div',{staticClass:"d-flex justify-content-right"},[_c('b-form-checkbox',{model:{value:(_vm.task.withsms),callback:function ($$v) {_vm.$set(_vm.task, "withsms", $$v)},expression:"task.withsms"}}),_c('span',[_vm._v("  SMS")])],1):_vm._e()],1):_vm._e()],1),(!_vm.isDisabled)?_c('div',{staticClass:"float-right mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?[_c('b-spinner',{attrs:{"small":""}}),_c('span',[_vm._v("Loading...")])]:[_c('feather-icon',{attrs:{"icon":"ArrowUpIcon"}}),_c('span',[_vm._v("Update")])]],2)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }